import moment from "moment";
import React from "react";
import Popup from "../../../reusable-components/popup/popup";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  TextField,
  TableSortLabel,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tableTheme } from "../../../mui-theme";
import { ChangelogApi } from "api/changelog";

interface HistoryEntry {
  hotelId: number;
  dateMidday: number;
  timestamp: string;
  email: string;
  type: string;
  newValue: string;
  previousValue: string;
  roomTypeId: string;
  ratePlanId: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeHotel: {
    hotelId: number;
  };
  dayDate: number;
}

type HistoryModalProps = Props & { ref?: React.ForwardedRef<HistoryModal> };

interface State {
  history: HistoryEntry[];
  isLoading: boolean;
  error: string | null;
  searchQuery: string;
  sortConfig: {
    key: keyof HistoryEntry | null;
    direction: "asc" | "desc";
  };
}

class HistoryModal extends React.Component<HistoryModalProps, State> {
  state: State = {
    history: [],
    isLoading: false,
    error: null,
    searchQuery: "",
    sortConfig: {
      key: "timestamp",
      direction: "desc",
    },
  };

  private async fetchHistory() {
    try {
      this.setState({ isLoading: true, error: null });

      const res = await ChangelogApi.getHistory({
        hotelId: this.props.activeHotel.hotelId,
        dayDate: this.props.dayDate,
      });

      const cleanedHistory = res.sort(
        (a: HistoryEntry, b: HistoryEntry) =>
          Number(b.timestamp) - Number(a.timestamp),
      );

      this.setState({
        history: cleanedHistory,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        error: "Failed to load history data",
        isLoading: false,
      });
    }
  }

  componentDidMount() {
    if (this.props.dayDate) {
      this.fetchHistory();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.dayDate !== prevProps.dayDate) {
      this.fetchHistory();
    }
  }

  private handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: event.target.value,
    });
  };

  private handleSort = (key: keyof HistoryEntry) => {
    const direction =
      this.state.sortConfig.key === key &&
      this.state.sortConfig.direction === "asc"
        ? "desc"
        : "asc";

    this.setState({
      sortConfig: {
        key,
        direction,
      },
    });
  };

  private getFilteredAndSortedData = () => {
    const { history, searchQuery, sortConfig } = this.state;

    // Apply search filter
    let filteredData = history.filter((item) => {
      const query = searchQuery.toLowerCase();
      const timestamp = moment(Number(item.timestamp))
        .format("YYYY-MMM-DD HH:mm:ss")
        .toLowerCase();
      return (
        timestamp.includes(query) ||
        (item.email?.toLowerCase() || "").includes(query) ||
        (item.type?.toLowerCase() || "").includes(query) ||
        (item.newValue?.toString() || "").toLowerCase().includes(query) ||
        (item.previousValue?.toString() || "").toLowerCase().includes(query) ||
        (item.roomTypeId?.toString() || "").toLowerCase().includes(query) ||
        (item.ratePlanId?.toString() || "").toLowerCase().includes(query)
      );
    });

    // Apply sorting
    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key!];
        const bValue = b[sortConfig.key!];

        if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    return filteredData;
  };

  private renderTableContent() {
    const { isLoading, error } = this.state;
    const filteredAndSortedData = this.getFilteredAndSortedData();

    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            Loading...
          </TableCell>
        </TableRow>
      );
    }

    if (error) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center" style={{ color: "red" }}>
            {error}
          </TableCell>
        </TableRow>
      );
    }

    if (filteredAndSortedData.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            No history data available
          </TableCell>
        </TableRow>
      );
    }

    return filteredAndSortedData.map((row, index) => (
      <TableRow key={`${row.timestamp}-${index}`}>
        <TableCell>
          {moment(Number(row.timestamp)).format("DD MMM YYYY HH:mm")}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.previousValue}</TableCell>
        <TableCell>{row.newValue}</TableCell>
        <TableCell>{row.roomTypeId}</TableCell>
        <TableCell>{row.ratePlanId}</TableCell>
      </TableRow>
    ));
  }

  render() {
    const { sortConfig } = this.state;

    const content = [
      <ThemeProvider theme={tableTheme} key="table-provider">
        <div>
          <TextField
            fullWidth
            size="small"
            placeholder="Search in all fields..."
            value={this.state.searchQuery}
            onChange={this.handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "calc(90vh - 300px)",
              overflow: "auto",
              "& .MuiTable-root": {
                tableLayout: "fixed",
                "& .MuiTableCell-root": {
                  padding: "8px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  "&:nth-of-type(1)": { width: "15%" }, // Timestamp
                  "&:nth-of-type(2)": { width: "20%" }, // Email
                  "&:nth-of-type(3)": { width: "20%" }, // Action
                  "&:nth-of-type(4)": { width: "10%" }, // Previous Value
                  "&:nth-of-type(5)": { width: "10%" }, // New Value
                  "&:nth-of-type(6)": { width: "12.5%" }, // Room Type
                  "&:nth-of-type(7)": { width: "12.5%" }, // Rate Plan
                },
              },
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "timestamp"}
                      direction={sortConfig.direction}
                      onClick={() => this.handleSort("timestamp")}
                    >
                      Timestamp
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "email"}
                      direction={sortConfig.direction}
                      onClick={() => this.handleSort("email")}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "type"}
                      direction={sortConfig.direction}
                      onClick={() => this.handleSort("type")}
                    >
                      Action
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "previousValue"}
                      direction={sortConfig.direction}
                      onClick={() => this.handleSort("previousValue")}
                    >
                      Previous Value
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "newValue"}
                      direction={sortConfig.direction}
                      onClick={() => this.handleSort("newValue")}
                    >
                      New Value
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "roomTypeId"}
                      direction={sortConfig.direction}
                      onClick={() => this.handleSort("roomTypeId")}
                    >
                      Room Type
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "ratePlanId"}
                      direction={sortConfig.direction}
                      onClick={() => this.handleSort("ratePlanId")}
                    >
                      Rate Plan
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderTableContent()}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </ThemeProvider>,
    ];

    const title = this.props.dayDate
      ? `History for ${moment(this.props.dayDate).format("DD MMM YYYY")}`
      : "History";

    return (
      <Popup
        isPopupOpen={this.props.isOpen}
        title={title}
        content={content}
        action={this.props.onClose}
        actionName="Close"
        cancelAction={this.props.onClose}
        cancelName="NA"
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
});

const ConnectedHistoryModal = connect(mapStateToProps)(HistoryModal);

export default React.forwardRef<HistoryModal, Props>((props, ref) => (
  <ConnectedHistoryModal {...props} ref={ref} />
));
