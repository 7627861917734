import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { AuthService } from "./services/auth";
import { ConfigService } from "./services/config";
import {
  ConfirmSignIn,
  ConfirmSignUp,
  SignUp,
  VerifyContact,
  withAuthenticator,
} from "aws-amplify-react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { connect } from "react-redux";
import "../config/amplify";
import "./App.scss";
import { ForgotPassword } from "./auth/ForgotPassword";
import { RequireNewPassword } from "./auth/RequireNewPassword";
import { ResetPassword } from "./auth/ResetPassword";
import { SignIn } from "./auth/SignIn";
import ExecSummary from "./settings/executiveSummary/execSummary";
import Settings from "./settings/settings";
import Dashboard from "./dashboard/dashboard";
import Navbar from "./Navbar";
import { ToastContainer } from "react-toastify";
import SessionHandler from "../components/SessionHandler";
import { AppService, YieldSheetService } from "./services";
import { State } from "../models/app.models";
import OnboardHotel from "./onboard-hotel/onboardHotel";
import { appStore } from "./store";
import Users from "./settings/users/users";
import NewHotelConfig from "./settings/HotelConfig/NewHotelConfig";
import NewCompSetConfig from "./settings/CompSetConfig/NewCompSetConfig";
import RoomConfig from "./settings/RoomConfig/RoomConfig";
import HomePage from "../App/home-page/homePage";
import { UserApi } from "../api/user";
import PageNotFound from "./not-found/pageNotFound";
import Popup from "../reusable-components/popup/popup";
import { store } from "redux/store";
import { resetHotel } from "redux/Slice/hotelSlice";
import { resetState } from "redux/Slice/state";

interface StateProps {
  activeHotel: {
    hotelId: number;
  };
  hotels: any[];
}

interface AppProps extends StateProps {
  appStore: any;
  onStateChange: (state: string, data: any) => void;
}

class App extends Component<AppProps, State> {
  state = {
    username: null,
    permissions: null,
    count: 1,
    hotel: null,
    yieldSheetCheck: false,
    monthlyPopupValue: "false",
    invalidHotelId: false,
  };
  _isMounted: boolean = false;
  currentUser: string | undefined;
  adminUser: string | undefined;
  hotel: any;
  deleteFormula: any;
  settingHotel: any;
  users: any[] | undefined;
  otaCompSetList = [];

  constructor(props: any) {
    super(props);
    this.changeInvalidIdStaten = this.changeInvalidIdStaten.bind(this);
  }

  logout = () => {
    Auth.signOut().then(() => {
      this.props.onStateChange("signedOut", null);
      this.props.appStore.reset();
      store.dispatch(resetHotel());
      store.dispatch(resetState());
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this._isMounted = true;
    let currentUser: any;
    const startNumber = /[a-zA-Z]/;
    const pathname = window.location.pathname;
    const part = pathname.split("/");

    this.props.appStore.yieldSheet.outOfRange = new Set();
    if (this.isIdle(this.props.appStore)) {
      currentUser = AuthService.revanista.user.identify(this.props);
      await AppService.revanista.initialize(currentUser, this.props);
      let allHotels = this.props.hotels || [];
      let hotelIds = allHotels.map((data: any) => data.hotelId);
      if (
        part[1].length !== 0 &&
        (!hotelIds.includes(parseInt(part[1])) || startNumber.test(part[1]))
      ) {
        // check conditions in case of 1017e
        this.setState({ invalidHotelId: true });
        return;
      }
      ConfigService.revanista.hotel.configure(currentUser);
      if (this._isMounted) {
        this.setState(currentUser);
      }
    }

    setTimeout(() => {
      this.fetchPopupValue();
    }, 3000);
  };

  fetchPopupValue = async () => {
    let res = await YieldSheetService.revanista.hotel.getAttribute(
      appStore.meta.user["zwtToken"],
    );

    let monthlyPopupValue = res.UserAttributes.find(
      (data: any) => data.Name === "custom:monthlyRateReminder",
    );

    this.setState({ monthlyPopupValue: monthlyPopupValue.Value });

    return res;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeInvalidIdStaten() {
    this.setState({ invalidHotelId: false });

    this.fetchData();
  }

  isInitializing(store: any) {
    const { requests } = store;
    return requests.auth.signin.state === "fetching";
  }

  isIdle(store: any) {
    const { requests } = store;
    return requests.auth.signin.state === "idle";
  }

  hasErroredOut(store: any) {
    const { requests } = store;

    return requests.auth.signin.state === "error";
  }

  hasNotErroredOut(store: any) {
    return !this.hasErroredOut(store);
  }

  getHotelPropsData(data: any) {
    this.setState({ hotel: data });
  }

  handleOnPopupClose = async () => {
    this.setState({ monthlyPopupValue: "false" });
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    let firstRoute = parseInt(parts[1]);
    await UserApi.updateMonthlyPopupValue({
      hotelIds: firstRoute || this.props.activeHotel.hotelId,
      userEmail: appStore.meta.user.username,
      customPermission: appStore.meta.user.permissions,
    })
      .then((data) => this.fetchPopupValue())
      .catch((err) => console.log(err));
  };

  handleOnRemindMeLater = async () => {
    this.setState({ monthlyPopupValue: "false" });
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    let firstRoute = parseInt(parts[1]);
    await UserApi.remindMeLater({
      hotelIds: firstRoute || this.props.activeHotel.hotelId,
      userEmail: this.props.appStore.meta.user.username,
      customPermission: this.props.appStore.meta.user.permissions,
    })
      .then((data) => this.fetchPopupValue())
      .catch((err) => console.log(err));
  };

  render() {
    const { appStore } = this.props;
    const adminUser = appStore.meta._user.permissions.admin;
    console.log("user", appStore.meta.user);
    let firstRoute: number;
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    firstRoute = parseInt(parts[1]);
    if (this.props.hotels) {
      if (this.props.hotels.length === 1) {
        firstRoute = parseInt(this.props.hotels[0].hotelId);
      }
    }

    console.log("first route ", firstRoute);

    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 23);
    let after23Months = currentDate.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });

    return (
      <>
        <SessionHandler />
        <BrowserRouter>
          {this.state.invalidHotelId === true ? (
            <Fragment>
              <PageNotFound
                activeHotelId={firstRoute}
                onLogout={this.logout}
                username={appStore.meta.user.username}
                changeIDState={this.changeInvalidIdStaten}
              />
            </Fragment>
          ) : (
            <Fragment>
              {this.isInitializing(appStore) ||
              this.props.hotels.length === 0 ||
              (this.isIdle(appStore) && this.hasNotErroredOut(appStore)) ? (
                <Backdrop open={true} invisible={true}>
                  <CircularProgress color="inherit" id="circular-progress" />
                </Backdrop>
              ) : this.hasErroredOut(appStore) ||
                store.getState().appState.hasError ? (
                <div className="no-data--simple-header-text">
                  <h1 className="text-center">
                    An unexpected error has occurred!
                  </h1>
                </div>
              ) : (
                <Fragment>
                  <Navbar
                    onLogout={this.logout}
                    username={appStore.meta.user.username}
                    activeHotelId={firstRoute}
                  />
                  <ToastContainer hideProgressBar={true} />
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <>
                          <HomePage />
                        </>
                      }
                    />
                    {adminUser[0] === "*" && (
                      <Route
                        path="/onboard-hotel"
                        element={
                          <>
                            <OnboardHotel />
                          </>
                        }
                      />
                    )}
                    <Route path="/:hotelId" element={<Dashboard />} />
                    {/* Nested Routes for setting */}
                    <Route
                      path="/:hotelId/settings"
                      element={<Settings adminUser={adminUser} />}
                    >
                      <Route
                        path="executive-summary"
                        element={
                          <>
                            <ExecSummary />
                          </>
                        }
                      />
                      <Route path="user" element={<Users />} />
                      <Route path="hotel-config" element={<NewHotelConfig />} />
                      <Route
                        path="rooms"
                        element={
                          <>
                            <RoomConfig />
                          </>
                        }
                      />
                      {adminUser[0] === "*" && (
                        <Route
                          path="competitive-set"
                          element={
                            <>
                              <NewCompSetConfig />
                            </>
                          }
                        />
                      )}
                    </Route>

                    <Route
                      path="*"
                      element={
                        <PageNotFound
                          activeHotelId={firstRoute}
                          onLogout={this.logout}
                          username={appStore.meta.user.username}
                          changeIDState={this.changeInvalidIdStaten}
                        />
                      }
                    />
                  </Routes>
                </Fragment>
              )}
              <Popup
                isPopupOpen={this.state.monthlyPopupValue === "true"}
                title="Reminder"
                content={[
                  ` ${after23Months} is now available for rate loading. Please action at
          your convenience.`,
                ]}
                cancelAction={this.handleOnRemindMeLater}
                cancelName="Remind me later"
                action={this.handleOnPopupClose}
                actionName="OK"
              />
            </Fragment>
          )}
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
  hotels: state.hotelData.hotels,
});

const AppObserver = connect(mapStateToProps)(inject("appStore")(observer(App)));
export { AppObserver as App };

export default withAuthenticator(AppObserver, false, [
  <SignIn key="SignIn" />,
  <ConfirmSignIn key="ConfirmSignIn" />,
  <SignUp key="SignUp" />,
  <ConfirmSignUp key="ConfirmSignUp" />,
  <RequireNewPassword key="RequireNewPassword" />,
  <VerifyContact key="VerifyContact" />,
  <ForgotPassword key="ForgotPassword" />,
  <ResetPassword key="ResetPassword" />,
]);
